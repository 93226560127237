<div class="jumbotron h-100">
    <form [formGroup]="form">
      <div class="form-group">
        <label for="emailAddress">Email address</label>
        <input id="emailAddress" name="emailAddress" formControlName="emailAddress" type="email" class="form-control" placeholder="Enter email">
      </div>
      <div class="form-group">
        <label for="message">Message</label>
        <textarea id="message" name="message" formControlName="message" class="form-control"></textarea>
      </div>
      <div class="form-group row mb-5">
        <div class="col-sm-12">
          <ngx-recaptcha2
            [siteKey]="siteKey"
            [size]="size"
            [useGlobalDomain]="false"
            #captchaElem
            class="g-recaptcha"
            formControlName="recaptcha"
          ></ngx-recaptcha2>
        </div>
      </div>
      <div class="controls-wrap mt-5">
        <div class="controls d-flex " [ngClass]="{ 'justify-content-end': !success}">
          <div *ngIf="success" class="alert alert-success mb-0"> <i class="fas fa-check mr-5"></i> Your e-mail was successfully sent!</div>
          <button class="btn btn-primary btn-lg no-w mr-2" (click)="sendEmail()">
            <i class="fa fa-paper-plane"></i>
            Submit
          </button>

        </div>
      </div>
    </form>
  </div>