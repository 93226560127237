import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { AppConfig } from './app-settings.service';
import { Observable } from 'rxjs';
import { User } from '../models';
import { ValidateResponse } from '@shared/models/validate.status';
@Injectable({ providedIn: 'root' })
export class DataService {
  constructor(private http: HttpClient) {  }

  getLoginRedirectUrl(): Observable<string> {

    return this.http.get(this.url('account/login'), {responseType: 'text'}) as any;
  }
  
  getAuthorizationToken(loginResult: any): Observable<any> {
    return this.http.post<any>(this.url('account/loggedin'), loginResult, {responseType: 'text'} as any);
    // return this.http.post<any>(this.url('account/loggedin'), loginResult);
  }

  logout(){
    return this.http.get(this.url('account/logout'), {responseType: 'text'});
  }

  singleLogout(samlRequest: string, signature: string, sigAlg: string): any {
    const req = encodeURIComponent(samlRequest);
    const sign = encodeURIComponent(signature);
    const alg = encodeURIComponent(sigAlg);

    return this.http.get(this.url(`account/singleLogout?SAMLRequest=${req}&Signature=${sign}&SigAlg=${alg}`), {responseType: 'text'});
  }

  getProfileInfo(): Observable<User> {
    return this.http.get<User>(this.url('personal/profile'));
  }
  uploadDocument(file: File) {
    const formData: FormData = new FormData();
    formData.append('files', file, file.name);

    return this.http.post(this.url('documents/upload'), formData);
  }

  signDocument(sessionId, pin) {
    const body = {
      sessionId,
      pin,
      signatureInfo: {
            PositionX: 20,
             PositionY: 37,
            SignatureBoxWidth: 200,
            SignatureBoxHeight: 50,
            Contact: '+5+65',
            Location: 'qeqwe',
          Reason: 'qweqwe' }
      }


    return this.http.post(this.url('documents/sign'), body);
  }

  downloadDocument(sessionId) {
    return this.http.get(this.url(`documents/download?sessionId=${sessionId}`));
  }

  verifyDocument(file: File): Observable<ValidateResponse> {
    const formData: FormData = new FormData();
    formData.append('files', file, file.name);

    return this.http.post<ValidateResponse>(this.url('documents/validate'), formData);
  }

  sendByEmail(sessionId, email, message) {
    const request = {
      subject: "Zamsign signed document",
      message,
      sessionId,
      to: [
        email
      ],
      "cc": [],
      "bcc": []
    };
    return this.http.post(this.url('documents/SendByEmail'), request);
  }

  private url(url: string): string {
    const prefix = AppConfig.settings.apiUrl[AppConfig.settings.apiUrl.length - 1] === '/' ? '' : '/';
    return AppConfig.settings.apiUrl.concat(prefix, url);
  }
 }
