import { Component } from '@angular/core';
import { LoginService } from 'src/app/shared/services/login.service';

@Component({
  selector: 'app-toolbar',
  templateUrl: './toolbar.component.html',
  styleUrls: ['./toolbar.component.scss'],
})
export class ToolbarComponent {
  constructor(public loginService: LoginService) {

  }

  get user() {
    return this.loginService.user;
  }

  get isLoggedIn() {
    return this.loginService.isLoggedIn;
  }

  doLogOut() {
    this.loginService.logout();
  }
  myProfileMenu = false;
  languageMenu = false;
}
