<style id="fontSize"></style>
<nav class="navbar navbar-default navbar-static-top">
  <div class="navbar-container">
    <div class="container">
      <div class="row">
        <div class="col-11 col-lg-5 main-navbar">
          <div class="brand-wrap">
            <a [routerLink]="[homeRoute]" class="brand d-none d-sm-flex" [attr.aria-label]=" slogan">
              <app-picture
              [sources]="logoSources"
                [img]="logoImgs"
                >
              </app-picture>
            </a>
            <a [routerLink]="[homeRoute]" class="slogan">{{ slogan  }}</a>
          </div>
        </div>
        <div class="col-md-6 col-lg-7 d-none d-lg-block wrapper-navbar">
          <ul class="nav navbar-nav flex">
            <li>
              <a [routerLinkActive]="['active']" [routerLink]="['/sign']" class="nav-link">Sign electronically</a>
            </li>
            <li>
              <a [routerLinkActive]="['active']" [routerLink]="['/sign-status']" class="nav-link">Verify signature</a>
            </li>
            <!-- <li>
              <a [routerLink]="['news/1']" class="nav-link">Article</a>
            </li>
            <li>
              <a [routerLink]="['news']" class="nav-link">News</a>
            </li> -->
          </ul>
        </div>
        <button (click)="isHeaderCollapsed = !isHeaderCollapsed"
          class="px-1 col-1 btn btn-default navbar-toggle collapsed d-lg-none" type="button">
          <span class="sr-only">{{ 'Toggle navigation' }}</span>
          <i class="fa fa-bars"></i>
        </button>
      </div>
    </div>
  </div>
</nav>
<div [collapse]="!isHeaderCollapsed" class="collapse navbar-collapse d-lg-none d-xl-none wrapper-navbar in show">
  <ul class="nav navbar-nav">
    <li>
      <a (click)="isHeaderCollapsed = false" [routerLinkActive]="['active']" [routerLink]="['/sign']" class="nav-link">Sign electronically</a>
    </li>
    <li>
      <a (click)="isHeaderCollapsed = false" [routerLinkActive]="['active']" [routerLink]="['/sign-status']" class="nav-link">Verify signature</a>
    </li>
    <!-- <li>
      <a [routerLinkActive]="['active']" [routerLink]="['news/1']" class="nav-link">Article</a>
    </li>
    <li>
      <a [routerLinkActive]="['active']" [routerLink]="['news']" class="nav-link">News</a>
    </li> -->
  </ul>
</div>
