import { Component } from '@angular/core';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
})
export class HeaderComponent {
  isHeaderCollapsed = false;
  slogan = 'ZamSign - National Authentication and Access Control Service';
  homeRoute = '/';

  logoSources = [{
    type: 'image/webp',
    srcset: '/assets/images/logo.webp'
  }];

  logoImgs = {
    alt: 'Logo',
    src: '/assets/images/logo_125.png',
    srcset: ''
  };
}
