<div class="toolbar header-toolbar">
  <div class="container">
    <div class="navbar py-0">
      <div class="navbar-brand ">
      </div>
      <div class="form-inline ">
        <div class="nav d-flex px-4 py-2">
          <div dropdown class="dropdown ">
            <a href
            class="p-0"
              (click)="false" 
              dropdownToggle
              aria-controls="profile-dropdown"
              data-toggle="dropdown" 
              aria-haspopup="true" 
              aria-expanded="false"
              data-offset="10,20"
            >
              <i class="fa fa-user cmx-5"></i>
                My Profile
              <i class="fa fa-caret-down cmx-5"></i>
            </a>
            <ul *dropdownMenu id="profile-dropdown"  class="dropdown-menu">
              <li  class="dropdown-item" *ngIf="!isLoggedIn">
                <a (click)="myProfileMenu = false"  [routerLink]="['account','login']">
                  <i class="fa fa-user"></i>
                    Login with ZamPass
                </a>
              </li>
  
              <li  class="dropdown-item" *ngIf="isLoggedIn">
                <a (click)="doLogOut()">
                  <i class="fa fa-sign-out-alt"></i>
                  ZamPass Logout
                </a>
              </li>
            </ul>
          </div>

          <div class="flex-grow-1 flex-sm-grow-0 align-self-center username pl-sm-4" *ngIf="isLoggedIn">
            <i class="fas fa-user"></i>{{user?.fullName}}
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
