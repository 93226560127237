import { Injectable } from '@angular/core';
import { CanActivate, Router } from '@angular/router';
import { LoginService } from '../services/login.service';
import { LoginMetadata } from '@shared/models';

@Injectable()
export class AuthGuard implements CanActivate {
  constructor(
    private loginService: LoginService,
    private router: Router,
  ) {}

  canActivate(): boolean {
    return this._canActivate();
  }

  _canActivate(): boolean {
    if (this.loginService.isLoggedIn) {
      return true;
    }
    this.logout();
    return false;
  }

  logout() {
        // ex: /ep/permit/1
        localStorage.setItem(LoginMetadata.redirectUrl, window.location.hash.substr(1));
        // this.notification.warning('Login required.');
        this.router.navigate(['account/login']);
  }
}
