import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-verification-result-nav',
  templateUrl: './verification-result-nav.component.html',
  styleUrls: ['./verification-result-nav.component.scss']
})
export class VerificationResultNavComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
