import { NgModule, ModuleWithProviders } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';

import { NgxCaptchaModule } from 'ngx-captcha';

import { BreadcrumbsComponent, ProgressBarComponent, SelectDocumentComponent } from './components';
import { FormatKeyPipe } from './pipes/format-key.pipe';
import { AuthInterceptor } from './interceptors/auth.interceptor';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { AuthGuard, DataService, LoginService, StateService } from './services';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { SendEmailComponent } from './components/send-email/send-email.component';
import { ToastrModule } from 'ngx-toastr';
import { VerificationResultNavComponent } from './components/verification-result-nav/verification-result-nav.component';
import { HeaderComponent } from './components/header/header.component';
import { FooterComponent } from './components/footer/footer.component';
import { ToolbarComponent } from './components/toolbar/toolbar.component';
import { CollapseModule } from 'ngx-bootstrap/collapse';
import { BsDropdownModule } from 'ngx-bootstrap/dropdown';
import { PictureComponent } from './components/picture/picture.component';

const PROVIDERS = [{
      provide: HTTP_INTERCEPTORS,
      useClass: AuthInterceptor,
      multi: true
    },
    AuthGuard,
    DataService, 
    LoginService, 
    StateService ];
const COMPONENTS = [
                    ProgressBarComponent,
                    BreadcrumbsComponent,
                    FormatKeyPipe,
                    SelectDocumentComponent,
                    SendEmailComponent,
                    VerificationResultNavComponent,
                    HeaderComponent,
                    FooterComponent,
                    ToolbarComponent,
                    PictureComponent
                  ];

@NgModule({
  declarations: [...COMPONENTS],
  exports: [...COMPONENTS, NgxCaptchaModule, FormsModule, ReactiveFormsModule, ToastrModule],
  imports: [
    CommonModule,
    NgxCaptchaModule,
    RouterModule,
    FormsModule,
    ReactiveFormsModule,
    ToastrModule.forRoot({
      timeOut: 2000,
      positionClass: 'toast-bottom-right',
      preventDuplicates: true,
    }), 
  CollapseModule.forRoot(),
  BsDropdownModule.forRoot(),
  ],
  providers: [...PROVIDERS]
})
export class SharedModule {
  static forRoot(): ModuleWithProviders<SharedModule> {
    return {
      ngModule: SharedModule,
      providers: [ ...PROVIDERS ]
    }
  }
}

