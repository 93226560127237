<div class="row mb-5">
    <div class="col-12 col-lg-8">
      <h5>Upload the .PDF electronic document for signing</h5>
    </div>
  </div>
  
  <div class="row">
    <div class="col-lg-8 col-md-12">
      <form [formGroup]="form">
        <div class="jumbotron">
          <div class="mb-5">
            <div class="custom-file">
              <input class="custom-file-input" id="customFile" type="file" 
              accept=".pdf, application/pdf"
              (change)="onDocumentSelect($event.target.files)"
              formControlName="document" />
              <label class="custom-file-label" for="customFile">
                <ng-container *ngIf="!selectedDocument">
                  <i class="fas fa-file-upload"></i>
                  Drop files here to upload or
                  <span>Upload .PDF file...</span>
                </ng-container>
                <span>{{selectedDocument?.name}}</span>
              </label>
            </div>
            <div class=" mt-3 alert alert-danger"  *ngIf="documentControl?.errors && documentControl?.dirty">
              Upload document must be a pdf file
            </div>
          </div>
          <div class="form-group row mb-5" *ngIf="displaySigningReason">
            <label class="col-sm-4 form-label" for="SigningReason">Signing reason: *</label>
            <div class="col-sm-8">
              <input
                class="form-control large max-w-250"
                formControlName="SigningReason"
                id="SigningReason"
                type="text"
                required
              />
            </div>
          </div>
          <div class="form-group row mb-5">
            <div class="col-sm-12">
              <ngx-recaptcha2
                [siteKey]="siteKey"
                [size]="size"
                [useGlobalDomain]="false"
                #captchaElem
                class="g-recaptcha"
                formControlName="recaptcha"
              ></ngx-recaptcha2>
            </div>
          </div>
        </div>
  
        <div class="controls-wrap">
          <div class="controls">
            <a class="btn btn-link btn-primary btn-lg no-w mr-5" routerLink="/">
              <i class="fa fa-chevron-left"></i>
              Back
            </a>
            <button [disabled]="form.invalid" class="btn btn-primary btn-lg no-w mr-5" (click)="onSubmit()">
              <i class="fas fa-sign-out-alt"></i>
              {{submitText}}
            </button>
          </div>
        </div>
      </form>
    </div>
  
    <!-- service pay block -->
    <!-- <div class="col-lg-4 col-md-12 sidebar">
      <div class="jumbotron warning-block">
        <h5 class="mb-4">
          <i class="fa fa-info-circle"></i>
          Instructions
        </h5>
        <p>
          You can upload only .PDF documents. You can sign the electronic document is using one of the
          4 available methods:
        </p>
        <ol class="mt-3">
          <li>
            <a href="#">Mobile signature *</a>
          </li>
          <li>
            <a href="#">National electronic ID</a>
          </li>
          <li>
            <a href="#">Digital certificates</a>
          </li>
          <li>
            <a href="#">Username and password</a>
          </li>
        </ol>
        <p class="mt-2">
          * You need an HSM certificate to sign the electronic document using mobile signature. If you
          don't have HSM certificate, you can
        </p>
        <a href="#">apply for HSM certificate issuance.</a>
      </div>
    </div> -->
  </div>
  