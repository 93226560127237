import { Component, Input } from '@angular/core';


@Component({
  selector: 'app-picture',
  templateUrl: './picture.component.html',
  styleUrls: ['./picture.component.scss'],
})
export class PictureComponent {

  @Input()
  sources: { type: string, srcset: string }[];

  @Input()
  img: { alt: string, src: string, srcset: string };

  @Input()
  imgClass: string;

  @Input()
  classes: string;

  @Input()
  styles: string;

}
