import { Injectable } from '@angular/core';
import { HttpInterceptor, HttpHandler, HttpRequest, HttpEvent, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { LoginService } from '../services/login.service';

@Injectable()
export class AuthInterceptor implements HttpInterceptor {
    constructor(private loginService: LoginService) {}
    intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>>  {
        if (this.loginService.isLoggedIn) {
            let headers = new HttpHeaders();
            headers = headers.append('authorization', this.loginService.token);
            req = req.clone({
                headers: req.headers.set(
                  'Authorization',
                  `Bearer ${this.loginService.token}`
                )
            })
        }
        
        return next.handle(req);
    }
    
}