import { Component, Output, Input, EventEmitter } from '@angular/core';
import { Validators, FormGroup, FormBuilder } from '@angular/forms';
import { AppConfig } from '@shared/services';
import { IStep } from '@shared/models';
import { fileTypeValidator } from '../validators/file-type-validator';

@Component({
  selector: 'app-select-document',
  templateUrl: './select-document.component.html',
  styleUrls: ['./select-document.component.scss']
})
export class SelectDocumentComponent {
  @Input() submitText = '';
  @Input() displaySigningReason = false;
  @Output() submited = new EventEmitter<File>();
  form: FormGroup;
  siteKey = AppConfig.settings.recaptchaSiteKey;
  size: 'compact' | 'normal' = 'normal';
  steps: IStep[] = [];
  selectedDocument;

  constructor(private fb: FormBuilder) {
    this.form = this.fb.group({
      document: ['', Validators.compose([Validators.required, fileTypeValidator(['pdf'])])], //['', Validators.required],
      SigningReason: [ ],
      recaptcha: ['', Validators.required]
    });
  }

  onDocumentSelect($event: any) {
    if (this.documentControl?.valid) {
      this.selectedDocument = $event[0]

      return;
    }

    this.selectedDocument = null;
  }

  onSubmit() {
     this.form.valid && this.selectedDocument ? this.submited.emit(this.selectedDocument) : null ;
  }

  get documentControl () {
    return this.form.get('document');
  }
}
