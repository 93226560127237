
export class Auth {
  constructor(
    public  sub: string, 
    public jti: string, 
    public email: string, 
    public given_name: string, 
    public family_name: string, 
    public sid: string, 
    public iat: string, 
    public exp: string, 
    public iss: string, 
    public aud: string,
  ) {
  }
  
  get fullName() {
    return `${this.given_name} ${this.family_name}`;
  }

  static fromResponse(response: any) {
    return new Auth(
    response['sub'], 
    response['jti'], 
    response['email'], 
    response['given_name'], 
    response['family_name'], 
    response['sid'], 
    response['iat'], 
    response['exp'], 
    response['iss'], 
    response['aud']
    );
  }
}
