<div class="stepper">
  <ul>
    <li [class.active]="rla.isActive" *ngFor="let step of steps; let i = index">
      <a [routerLink]="step.route" #rla="routerLinkActive" routerLinkActive="active">
        <i class="fa fa-check-circle"></i>
        <!--visible only for the past ones-->
        <i [ngClass]="rla.isActive ? 'fa-circle' : 'fa-circle-o'" class="fa"></i>
        {{ i + 1 }}. {{ step.title }}
      </a>
      <span>{{ i !== steps.length - 1 ? '/' : '' }}</span>
    </li>
  </ul>
</div>
