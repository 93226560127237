export class User
{
    PhotoUrl: string;
    AccountLocked: string;
    EmailVerified: string;
    ResourceType: string;
    AccountState: string;
    Role: string;
    BirthDate: string;
    Language: string;
    SessionIndex: string;
    Address: string;
    Phone: string;
    Email: string;
    Country: string;
    LastName: string;
    FirstName: string;
    FullName: string;
    UserId: string;
    Gender: string;
    UserName: string;
    UserPrincipal: string;
    LoginProvider: string;
    Thumbnail: string;
    Region: string;
}