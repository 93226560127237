import { Component } from '@angular/core';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss'],
})
export class FooterComponent {
  year: number;

  zamPassSources = [{
    type: 'image/webp',
    srcset: '/assets/images/logo.webp'
  }];
  zamPassImgs = {
    alt: 'Logo',
    src: '/assets/images/logo_125.png',
    srcset: ''
  };

  constructor() {
    this.year = new Date().getFullYear();
  }
}
