import { Pipe, PipeTransform } from '@angular/core';

import { camelCaseToSpace } from '../utils/format-strings';

@Pipe({
  name: 'formatKey',
})
export class FormatKeyPipe implements PipeTransform {
  transform(value: string, type?: string): string {
    switch (type) {
      case 'camelCaseToSpace': {
        return camelCaseToSpace(value);
      }
      default:
        return camelCaseToSpace(value);
    }
  }
}
