import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AuthGuard } from '@shared/services/auth.guard';

const routes: Routes = [
  {
    path: '',
    loadChildren: () => import('./pages/home/home.module').then(m => m.HomeModule),
  },
  {
    path: 'sign-status',
    loadChildren: () =>
      import('./pages/sign-status/sign-status.module').then(m => m.SignStatusModule),
  },
  {
    path: 'sign',
    loadChildren: () =>
      import('./pages/sign/sign.module').then(m => m.SignModule),
      canActivate: [AuthGuard]
  },
  {
    path: 'faq',
    loadChildren: () => import('./pages/faq/faq.module').then(m => m.FAQModule),
  },
  {
    path: 'news',
    loadChildren: () => import('./pages/news/news.module').then(m => m.NewsModule),
  },
  {
    path: 'news/:slug',
    loadChildren: () => import('./pages/one-news/one-news.module').then(m => m.OneNewsModule),
  },
  {
    path: 'terms-conditions',
    loadChildren: () =>
      import('./pages/terms-conditions/terms-conditions.module').then(m => m.TermsConditionsModule),
  },
  {
    path: 'account',
    loadChildren: () => import('./pages/account/accout.module').then(m => m.AccountModule)
  },
  {
    path: 'loggedin',
    redirectTo: 'account/loggedin'
  },
  {
    path: 'single_logout',
    redirectTo: 'account/single_logout'
  },
  { path: '**', redirectTo: '', pathMatch: 'full' },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
    useHash: true,
    scrollPositionRestoration: 'top',
    anchorScrolling: 'enabled',
    relativeLinkResolution: 'legacy'
}),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule {}
