import { Component, OnInit, Input } from '@angular/core';
import { AppConfig, DataService } from '@shared/services';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';

@Component({
  selector: 'app-send-email',
  templateUrl: './send-email.component.html',
  styleUrls: ['./send-email.component.scss']
})
export class SendEmailComponent implements OnInit {
  @Input() sessionId: string;
  siteKey = AppConfig.settings.recaptchaSiteKey;
  size: 'compact' | 'normal' = 'normal';
  form: FormGroup;
  success = false;
  constructor(private fb: FormBuilder, private dataService: DataService) { }

  ngOnInit() {
    this.form = this.fb.group({
      emailAddress: ['', Validators.required],
      message: [''],
      recaptcha: ['', Validators.required]
    });
  }

  sendEmail() {
    // TODO: add validation check
    const f = this.form.value;

    this.dataService.sendByEmail(this.sessionId, f.emailAddress, f.message).subscribe(res => {
      if (res) {
        this.success = true;
      } else {
        this.success = false;
      }
    },error => {
      this.success = false;
      console.log('email send failed. ', error && error.error);
    });
  }

}
