import {  FormControl } from "@angular/forms";
export function fileTypeValidator(type: string[]): any {
  return (control: FormControl) => {
    const file = control.value;
    let existValue = false;
   
    if (file) {

      const path = file.replace(/^.*[\\\/]/, "");

      const extension = path.split(".").pop().toUpperCase();

      for (let i = 0; i < type.length; i++) {
        const typeFile = type[i].toUpperCase();
        
        if (typeFile === extension.toUpperCase()) {
          existValue = true;
        }
      }
      
      if (existValue === true) {
        return null;
      } else {
        return {
          requiredFileType: true
        };
      }
    }
    
    return null;
  };
}