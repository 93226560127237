<div class="controls-wrap mt-5">
    <div class="controls">
      <div class="left-controls">
        <button class="btn btn-primary btn-lg no-w mr-5" routerLink="../sign-status">
          <i aria-hidden="true" class="fa fa-search"></i>
          Verify another document
        </button>
        <a class="btn btn-link btn-primary btn-lg no-w mr-5" routerLink="/">
          <i aria-hidden="true" class="fa fa-check"></i>
          Go to homepage
        </a>
      </div>
    </div>
  </div>