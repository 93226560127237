<footer class="footer mt-auto">
  <div class="container pt-4 pl-md-5">
    <div class="row">
      <div class="col-12 col-sm-6 col-md-3 footer__logo mb-2 pt-3">
        <app-picture
          [sources]="zamPassSources"
          [img]="zamPassImgs"
          imgClass="img-fluid"
          class="d-flex justify-content-center"
          styles="width: 70px;"
          >
        </app-picture>
        <p class="f-semi mt-10 fs-16 mb-4">Government of the Republic of Zambia</p>
        
      </div>
      <div class="col-12 col-sm-6 col-md-3 mb-30 pt-4  pl-4 pl-md-0 pl-lg-0 pl-xl-0">
        <h3 class="footer__label">Useful Links</h3>
        <hr>
        <ul class="nav flex-column">
          <li class="nav-item">
            <a class="nav-link fs-16 f-semi pl-0" routerLink="#">About Portal</a>
          </li>
          <li class="nav-item">
            <a class="nav-link fs-16 f-semi pl-0" routerLink="#">Help</a>
          </li>
          <li class="nav-item">
            <a class="nav-link fs-16 f-semi pl-0" routerLink="terms-conditions">Terms And Conditions</a>
          </li>
          <li class="nav-item">
            <a class="nav-link fs-16 f-semi pl-0" routerLink="faq">FAQ</a>
          </li>
        </ul>
      </div>
      <div class="col-12 col-sm-6 col-md-3 mb-30 pt-4  pl-4 pl-md-0 pl-lg-0 pl-xl-0">
        <h3 class="footer__label">Social Networks</h3>
        <hr>
        <ul class="nav flex-column footer__social">
          <li class="nav-item">
            <a class="nav-link fs-16 f-semi pl-0" href="http://facebook.com">
              <i class="fab fa-facebook-square"></i>
              Facebook
            </a>
          </li>
          <li class="nav-item">
            <a class="nav-link fs-16 f-semi pl-0" href="http://youtube.com">
              <i class="fab fa-youtube-square"></i>
              Youtube
            </a>
          </li>
          <li class="nav-item">
            <a class="nav-link fs-16 f-semi pl-0" href="http://twitter.com">
              <i class="fab fa-twitter-square"></i>
              Twitter
            </a>
          </li>
          <li class="nav-item">
            <a class="nav-link fs-16 f-semi pl-0" href="http://linkedin.com">
              <i class="fab fa-linkedin-square"></i>
              LinkedIn
            </a>
          </li>
          <li class="nav-item">
            <a class="nav-link fs-16 f-semi pl-0" href="http://instagram.com">
              <i class="fab fa-instagram"></i>
              Instagram
            </a>
          </li>
        </ul>
      </div>

      <div class="col-12 support col-sm-6 col-md-3 mb-30 pt-4  pl-4 pl-md-0 pl-lg-0 pl-xl-0">
        <h3 class="footer__label">{{ 'Support Call Center'}}</h3>
        <hr>
        <p class="m-0">Phone:</p>
        <a href="tel:+260211428600" class="footer__phone pb-0 pt-0">+260 <span>000 000</span></a>
        <p style="font-size: 15px;">
          (For electronic services portal)
        </p>
      </div>
    </div>
  </div>
  <div class="footer__copyright mt-2 text-white">
    <p>© {{ year}}
      <a href="https://www.cabinet.gov.zm/" target="_blank" rel="noreferrer" class="text-white">
        Government of the Republic of Zambia. All rights reserved. ZamSign
      </a>
    </p>
  </div>
</footer>
