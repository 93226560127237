import { BrowserModule } from '@angular/platform-browser';
import { NgModule, APP_INITIALIZER } from '@angular/core';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { AppConfig } from './shared/services/app-settings.service';
import { AuthInterceptor } from './shared/interceptors/auth.interceptor';

import { SharedModule } from '@shared/shared.module';

export function initializeApp(appConfig: AppConfig) {
  return () => appConfig.load();
};

@NgModule({
  declarations: [AppComponent],
  imports: [SharedModule.forRoot(), BrowserModule, BrowserAnimationsModule, AppRoutingModule, HttpClientModule],
  bootstrap: [AppComponent],
  providers: [
    AppConfig,
    {
      provide: APP_INITIALIZER,
      useFactory: initializeApp,
      deps: [AppConfig],
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthInterceptor,
      multi: true
    }  
  ],
})
export class AppModule {
 }
