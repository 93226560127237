import { Injectable } from '@angular/core';
import { DataService } from './data.service';
import { Auth } from '../models';
import { tap } from 'rxjs/operators';

const AUTH_KEY = 'auth';

@Injectable({providedIn: 'root'})
export class LoginService {
    constructor(private dataService: DataService) { 
        this.authToken = localStorage.getItem(AUTH_KEY) || '';
        this.setProfileInfo();
    }
    logoutUrl = '';
    actionUrl = '';
    private authToken = '';
    private _loggedBy;
    private profileInfo: Auth;
    static get NOT_LOGGED() {
        return -1;
      }
    
      static get CUSTOM_LOGIN() {
        return 1;
      }
    
      static get ZAMPASS_LOGIN() {
        return 2;
      }

    get isLoggedIn(): boolean {
        return !!this.authToken;
    }

    get token() {
        return this.authToken;
    }

    get user() {
        return this.profileInfo;
    }

    get LOGIN_TYPE() {
        return this._loggedBy || LoginService.NOT_LOGGED;
      }

    set loggedBy(value: any) {
    this.updateLoginStatus(value);
    }

    authenticateUser(loginResults:any) {
       return this.dataService.getAuthorizationToken(loginResults).pipe(tap((token: string) => {
            if (token) {
                this.setToken(token);
                this.setProfileInfo();
            }
        }));
    }

    public setProfileInfo() {
        if (!this.token) {
            return;
        }

        const info = Auth.fromResponse(JSON.parse(atob(this.token.split('.')[1])));
        this.profileInfo = info as any;
        // this.dataService.getProfileInfo().subscribe(profileInfo => {
        //     this.profileInfo = profileInfo;
        // });
    }

    public logout() {
        this.dataService.logout().subscribe((url) => {
            
            if(url) {
                window.location.href = url;
            }
        });
        
    }

    public singleLogout(samlRequest: string, signature: string, sigAlg: string): any {
        
        return this.dataService.singleLogout(samlRequest, signature, sigAlg)
    }

    public deleteAuthenticationData(){
        this.authToken = '';
        localStorage.removeItem(AUTH_KEY);
    }

    private setToken(token: string) {
        localStorage.setItem(AUTH_KEY, token);
        this.authToken = token;
    }

    private updateLoginStatus(loginStatus: number) {
        localStorage.setItem('_loggedby', loginStatus.toString());
        this._loggedBy = loginStatus;
        // this.loginChanged.emit(loginStatus);
      }
}